import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import Schema from "../components/schema"
import GenericHero from "../components/Shared/Generic/Hero/GenericHero"
import { useLocale } from "../hooks"
import { useLocation } from "@reach/router"
import loadable from "@loadable/component"
import ArticleContent from "../ArticlePage/ArticleContent/ArticleContent"
import ArticleContentColumns from "../ArticlePage/ArticleContentColumns/ArticleContentColumns"
import PurposeContent from "../components/Shared/PurposeContent/PurposeContent"
import RecipeSection from "../components/Shared/Generic/RecipeSection/RecipeSection"
import SectionAlternateGridSE from "../ArticlePage/SectionAlternateGrid2/SectionAlternateGridSE"
import SliderImages from "../components/sections/SliderSection/SliderImages"
import Alternativeicon from "../components/sections/Alternativeicon/alternativeicon"
import SectionHeadingDescription from "../components/sections/section-heading-description/sectionHeadingDescription"
import SectionDropdown from "../components/sections/SectionDropdown/SectionDropdown"
import { Slider } from "../components/Shared/Slider/Slider"
import { getPathname } from "../utils/functions"
import ReferenceContent from "../ArticlePage/ReferenceContent/ReferenceContent"
import ArticleContentLogo from "../ArticlePage/ArticleContentLogo/ArticleContentLogo";
import CtaButton from "../components/button/button-top/CtaButton"

const PurposeVideo = loadable(
  () => import("../components/Shared/PurposeVideo/PurposeVideo")
)
const ArticleRelatedArticles = loadable(
  () => import("../ArticlePage/ArticleRelatedArticles/ArticleRelatedArtciles")
)
const RecipesRelatedRecipes = loadable(
  () => import("../components/Recipes/RelatedRecipes/RecipesRelatedRecipes")
)
// const SliderRecipes = loadable(()=> import ("../components/Recipes/RelatedRecipes/SliderRecipes"))
const ArticleProductSlider = loadable(
  () => import("../ArticlePage/ArticleProductSlider/ArticleProductSlider")
)
const ArticleContentMedia = loadable(
  () => import("../ArticlePage/ArticleContentMedia/ArticleContentMedia")
)
const SectionAlternateGrid = loadable(
  () => import("../ArticlePage/SectionAlternateGrid/SectionAlternateGrid")
)
import InfographicsHero from "../components/Shared/Generic/InfographicsHero/InfographicsHero"
import InfographicsHeroNarrow from "../components/Shared/Generic/InfographicsHeroNarrow/InfographicsHeroNarrow"


const Article = ({
  location,
  pageContext,
  data: {
    contentfulPageArticle: {
      title,
      id,
      seoImage,
      canonicalSlug,
      pageType,
      createdAt,
      updatedAt,
      categoryTitle,
      seoTitle,
      seoDescription,
      slug,
      thumbnailImage,
      sections,
      relatedArticles,
      relatedRecipes,
      relatedProducts,
      articlePageIcon,
      recipeIcon,
      joinLiptonSection,
      socialIcons,
      signUpCta
    },
    allContentfulPageArticle: { nodes: allLinks },
  },
}) => {
  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash
      const ele = document.querySelector(hash)
      if (hash && ele) {
        const scrollTo = ele.offsetTop - 130
        window.scrollTo({
          top: scrollTo,
          behavior: "smooth",
        })
      }
    }, 250)
  }, [])
  

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-navigation-color",
      "#000"
    )
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "#000"
    )
  }, [])
  const locale = useLocale()

  const[form,updateForm]=useState(false);
  const toggleForm = () =>{
    updateForm(!form)
  }
  return (
    <>
      <Seo
        title={seoTitle}
        lang={locale}
        description={seoDescription?.seoDescription}
        productImage={seoImage && seoImage[0]}
        slick={false}
        hrefLangs={allLinks}
      />
      <Schema
        type={"Article"}
        title={title}
        desc={seoDescription?.seoDescription}
        url={location.href}
        created={createdAt}
        updated={updatedAt}
        image={thumbnailImage && thumbnailImage[0].fluid.src}
        slug={getPathname(pageContext.node_locale, slug)}
      />
      {sections.map((section: any, index: number) => {
        if (section.__typename === "ContentfulComponentHero") {
          if (section.type === "Infographics Hero") {
            return (
              <InfographicsHero
                title={title}
                fluid={section.backgroundImage}
                fluidMob={
                  section.backgroundImageMobile
                    ? section.backgroundImageMobile
                    : null
                }
              />
            )
          } else if (section.type === "Infographics Hero Narrow") {
            return (
              <InfographicsHeroNarrow
                fluid={section.backgroundImage}
                fluidMob={
                  section.backgroundImageMobile
                    ? section.backgroundImageMobile
                    : null
                }
              />
            )
          } else {
            return (
              <div id={`section-${index}`}>
                <GenericHero
                  socialIcons={!(socialIcons === false)}
                  subtitle={categoryTitle}
                  subheading={section.subheading}
                  title={title}
                  description={section?.description?.description}
                  fluid={section?.backgroundImage?.fluid}
                  fluidMob={section?.backgroundImageMobile?.fluid}
                  altText={section?.backgroundImage?.title}
                  fluidIcon={articlePageIcon?.fluid}
                  shortDescription={section?.shortDescription?.shortDescription}
                  key={index}
                  pageType={pageType}
                  firstChild={index === 0}
                />
              </div>
            )
          }
        }else if (section.__typename === "ContentfulComponentCta") {
          return (
            <div id={`section-${index}`}>
              <CtaButton ctaText={section.ctaText} form={form} toggleForm={toggleForm}/>
            </div>
          )
        } else if (section.__typename === "ContentfulComponentContent") {
          return (
            <div id={`section-${index}`}>
              {section.type==='referenceContent' ? 
              <ReferenceContent
                content={section.content}
                alignment={section.alignment}
                heading={section.heading}
                description={section.description}
                key={index}
                sourceText={section.sourceText}
              /> : 
              <ArticleContent
                content={section.content}
                alignment={section.alignment}
                heading={section.heading}
                description={section.description}
                key={index}
                sourceText={section.sourceText}
              />
              }
            </div>
          )
        } else if (section.__typename === "ContentfulComponentContentMedia") {
          return (
            <ArticleContentMedia
              heading={section.heading}
              image={section?.mediaImage}
              alternativemediaImage={section.alternativemediaImage}
              key={index}
            />
          )
        } else if (section.__typename === "ContentfulComponentContentLogo") {
          return (
            <ArticleContentLogo 
            logoimage={section?.logoImage}
            slug={section?.slug}/>
          )
        }
        else if (section.__typename === "ContentfulComponentColumns") {
          return (
            <ArticleContentColumns
              key={index}
              leftColumn={section.leftColumn}
              rightColumn={section.rightColumn}
            />
          )
        } else if (
          section.__typename === "ContentfulComponentRelatedProductsArticles"
        ) {
          return <Slider data={section} />
        } else if (section.__typename === "ContentfulComponentAlternateGrid") {
          return (
            <div id={`section-${index}`}>
              <SectionAlternateGrid
                position={section.positionContent}
                sectionContent={section.sectionContent}
                sectionImage={section.sectionImage}
                title={section.title}
                key={index}
                type={section.imageSize ? section.imageSize : "Default"}
              />
            </div>
          )
        } else if (section.__typename === "ContentfulComponentAlternateGrid2") {
          return (
            <SectionAlternateGridSE
              position={section.positionContent}
              sectionContent={section.sectionContent}
              sectionImage={section.sectionImage}
              title={section.title}
              subtitle={section.subtitle}
              key={index}
              type={section.imageSize ? section.imageSize : "Default"}
              backgroundColor={section.backgroundColor}
              buttonTextLink={section.buttonTextLink}
              buttonText={section.buttonText}
            />
          )
        } else if (section.__typename === "ContentfulComponentSliderSection") {
          return <SliderImages data={section} />
        } else if (section.__typename === "ContentfulComponentYoutubeMedia") {
          return (
            <PurposeVideo
              key={index}
              backgroundImage={section.bannerImage}
              backgroundVideo={section.youtubeVideo}
              title={section.heading}
            />
          )
        } else if (
          section.__typename === "ContentfulComponentFeaturedItems" &&
          section.type === "componentGridItems" &&
          locale === "en-PK"
        ) {
          return <RecipeSection articles={section} />
        } else if (section.__typename === "ContentfulComponentPdf") {
          return (
            <div id={`section-${index}`}>
              <Alternativeicon
                relatedsection={section.relatedsection}
                slugTitle={section.slugTitle}
                slug={section.downloadasset}
              />
            </div>
          )
        } else if (
          section.__typename === "ContentfulComponentHeadingDescription"
        ) {
          return (
            <SectionHeadingDescription section={section} key={index} />
          )
        } else if (
          section.__typename === "ContentfulComponentGridItems"
        ) {
          return (
            <SectionDropdown section={section} key={index} />
          )
        }
      })}

      {relatedProducts && (
        <ArticleProductSlider
          productArray={relatedProducts}
          displayButton={true}
          extraClass="articlePage"
        />
      )}
      {/* <ProductSlider productArray={relatedProducts} displayButton={true} extraClass="articlePage"/> */}
      {/* <RelatedArticles articles={relatedArticles}/> */}
      {relatedArticles && (
        <ArticleRelatedArticles
          articles={relatedArticles}
          articlesIcon={articlePageIcon}
        />
      )}
      {relatedRecipes && (
        <RecipesRelatedRecipes
          articles={relatedRecipes}
          recipeIcon={recipeIcon}
        />
      )}
      {/* {
                sliderRecipes && < SliderRecipes articles={sliderRecipes} recipeIcon={recipeIcon} />
            } */}

      {joinLiptonSection && <PurposeContent object={joinLiptonSection} />}
    </>
  )
}

export const ArticleQuery = graphql`
  query articlePageAndArticlePage(
    $id: String!
    $node_locale: String!
    $contentful_id: String!
  ) {
    contentfulPageArticle(id: { eq: $id }) {
      signUpCta
      title
      createdAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
      updatedAt(formatString: "MMMM DD, YYYY")
      id
      pageType
      categoryTitle
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      socialIcons
      seoImage: thumbnailImage {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      thumbnailImage {
        title
        fluid(quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      articlePageIcon {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      sections {
        ... on ContentfulComponentContent {
          __typename
          type
          id
          heading
          alignment
          description {
            raw
          }
          content {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                id
                gatsbyImageData
                title
              }
            }
          }
        }
        ... on ContentfulComponentHero {
          __typename
          id
          name
          subheading
          description {
            description
          }
          shortDescription {
            shortDescription
          }
          backgroundImage {
            title
            fluid(maxWidth: 1600, quality: 60) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImageMobile {
            title
            fluid(quality: 50) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          type
        }
        ... on ContentfulComponentRelatedProductsArticles {
          __typename
          dynamicBullets
          articleTriangleAlign
          navigation
          title
          heading
          smalltitle
          type
          centeredAlign
          recipes {
            title
            slug
            heroImage {
              title
              fluid(quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            subTitle
            recipeIcon {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          articles {
            articlePageIcon {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
            thumbnailCategoryTitle
            thumbnailTitle
            title
            thumbnailImage {
              title
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          titleBarColor
        }
        ... on ContentfulComponentContentLogo {
          __typename
          name
          logoImage {
            title
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          slug
        }
        ... on ContentfulComponentContentMedia {
          __typename
          name
          heading
          mediaImage {
            title
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          alternativemediaImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulComponentAlternateGrid {
          __typename
          title
          positionContent
          sectionImage {
            title
            fluid(quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sectionContent {
            raw
          }
          imageSize
        }
        ... on ContentfulComponentPdf {
          __typename
          name
          relatedsection {
            backgroundImage {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
            pdFtitle
            pdfdownloadsection {
              file {
                fileName
                url
              }
            }
          }
        }
       
        ... on ContentfulComponentYoutubeMedia {
          __typename
          id
          name
          heading
          youtubeVideo
          bannerImage {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulComponentColumns {
          __typename
          name
          rightColumn {
            raw
          }
          leftColumn {
            raw
          }
        }

        ... on ContentfulComponentFeaturedItems {
          title
          __typename
          subtitle
          type
          icon {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImage {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sectionThumbnail {
            __typename
            ... on ContentfulPageArticle {
              title
              __typename
              thumbnailTitle
              slug
              articlePageIcon {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailImage {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailDescription {
                thumbnailDescription
              }
              thumbnailCategoryTitle
            }
            ... on ContentfulPageProducts {
              thumbnailDescription {
                thumbnailDescription
              }
              __typename
              slug
              title
              thumbnailTitle
              productImg: thumbnailImage {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            ... on ContentfulPageRecipe {
              __typename
              heroImage {
                fluid {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              title
              slug
              recipeIcon {
                fluid {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        ... on ContentfulComponentCta {
        __typename
        ctaText
        }
      }
      relatedArticles {
        title
        slug
        thumbnailCategoryTitle
        thumbnailImage {
          title
          fluid(maxWidth: 500, quality: 60) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        articlePageIcon {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      relatedRecipes {
        title
        slug
        heroImage {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        subTitle
        recipeIcon {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      relatedProducts {
        id
        slug
        title
        thumbnailImage {
          title
          fluid(maxWidth: 500, quality: 60) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        cta {
          title
        }
      }
      joinLiptonSection {
        heading
        alignment
        content {
          raw
        }
        cta {
          slug
          image {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allContentfulPageArticle(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`

export default Article
