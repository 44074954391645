/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocale, useSiteQuery, useUrl,useSearchQuery } from "../hooks"
import { useLocation } from '@reach/router';
import { siteUrl, mapLangSelector } from "../utils/mapping"
const Seo = ({
    description = null,
    lang = null,
    meta = null,
    title = null,
    seoImage = null,
    productImage = null,
    canonical = null,
    slick = false,
    hrefLangs = []
}) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )
    //const { href, origin } = useLocation()
    const location = useLocation()
    const baseUrl = useUrl()
    const { seoImageGlobal } = useSiteQuery()
    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    let image;
    let imageAltText;
    if (productImage) {
        image = productImage && productImage.fluid && productImage.fluid.src
        imageAltText = productImage && productImage.title
    } else if (seoImage) {
        image = seoImage[0] && seoImage[0].fluid && seoImage[0].fluid.src
        imageAltText = seoImage[0] && seoImage[0].title
    }
    //const canonicalSlug = `https://www.lipton.com/${baseUrl}/${canonical}`
    const {  wtbMetaName,wtbMetaContent } = useSearchQuery()

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={title}
                titleTemplate={defaultTitle ? `%s` : null}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    // {
                    //     property: 'og:url',
                    //     content: href
                    // },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata?.author || ``,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                    
                    // {
                    //     name: "ps-key",
                    //     //content: "1750-5dd55ca1852c0d0013107833" // old meta
                    //     content: "6426-64395b8b098506000df95112" // new meta for store locator
                    // },
                    // {
                    //     name: "ps-key",
                    //     content: "6426-64385e846536b8000e28feb9" // WTB
                    // },
                    {
                        name: wtbMetaName,
                        content: wtbMetaContent,
                      },

                ].concat(meta)}
            >
                {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css" integrity="sha512-wR4oNhLBHf7smjy0K4oqzdWumd+r5/+6QO/vDda76MW5iug4PT7v86FoEkySIJft3XA0Ae6axhIvHrqwm793Nw==" crossorigin="anonymous" referrerpolicy="no-referrer" defer="true" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" integrity="sha512-6lLUdeQ5uheMFbWm3CP271l14RsX1xtx+J5x2yeIDkkiBpeVTNhTqijME7GgRKKi6hCqovwCoBTlRBEC20M8Mg==" crossorigin="anonymous" referrerpolicy="no-referrer" defer="true"/> */}
                {/* {
                hreflangs && hreflangs.map(({regionSlug, regionLanguage, url},index:number)=>{
                    if (url === '/') {
                        let slugUrl = `https://www.pukkaherbs.com/${regionSlug}/${slug}.html`;
                        if (regionLanguage === 'en-GB') {
                            slugUrl = `https://www.pukkaherbs.com/uk/en/${slug}`;
                        }
        
                        if (slug === '/') {
                            if (regionLanguage === 'en-GB') {
                                slugUrl = `https://www.pukkaherbs.com/uk/en`;
                            } else {
                                slugUrl = `https://www.pukkaherbs.com/${regionSlug}`;
                            }
                        }
        
                        if (regionLanguage === 'nl-BE') {
                            if (slugUrl.includes('products')) {
                                slugUrl = slugUrl.replace('products', 'producten');
                            }
                        }

                        return <link key={index} rel="alternate" hrefLang={regionLanguage} href={slugUrl}></link>
                    } else {
                        return <link key={index} rel="alternate" hrefLang={regionLanguage} href={url}></link>
                    }
                })
            } */}
            </Helmet>
            {
                canonical
                    ? <Helmet>
                        {/* <link rel="canonical" href={canonicalSlug} /> */}
                        <link rel="canonical" href={`https://www.lipton.com${location.pathname}`}></link>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    : <Helmet>
                        <link rel="canonical" href={`https://www.lipton.com${location.pathname}`}></link>
                    </Helmet>
            }
            {/* {
                slick
                    ? <Helmet>
                        <link key="slick-carousel" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css" integrity="sha512-wR4oNhLBHf7smjy0K4oqzdWumd+r5/+6QO/vDda76MW5iug4PT7v86FoEkySIJft3XA0Ae6axhIvHrqwm793Nw==" crossOrigin="anonymous" referrerPolicy="no-referrer" defer />,
                        <link key="slick-carousel-theme" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" integrity="sha512-6lLUdeQ5uheMFbWm3CP271l14RsX1xtx+J5x2yeIDkkiBpeVTNhTqijME7GgRKKi6hCqovwCoBTlRBEC20M8Mg==" crossOrigin="anonymous" referrerPolicy="no-referrer" defer />
                    </Helmet>
                    : null
            } */}
            {
                <Helmet>
                    <meta name='image' content={`https:${image ? image : seoImageGlobal && seoImageGlobal.fluid && seoImageGlobal.fluid.src}`} />
                    <meta property='og:image' content={`https:${image ? image : seoImageGlobal && seoImageGlobal.fluid && seoImageGlobal.fluid.src}`} />
                    <meta property='og:image:secure_url' content={`https://secure.${(image ? image : seoImageGlobal && seoImageGlobal.fluid && seoImageGlobal.fluid.src).substr(2)}`} />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content={`${image ? '400' : '300'}`} />
                    <meta property="og:image:height" content="300" />
                    <meta property="og:image:alt" content={`${imageAltText ? imageAltText : "Lipton Website"}`} />
                </Helmet>
            }
            <Helmet>
            <link rel="alternate" hreflang="x-default" href={`https://www.lipton.com${location.pathname}`}></link>
                {
                    hrefLangs.map(({ slug, node_locale }, index) => {
                        return slug === '/'
                            ? node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/`} />)
                            : slug[slug.length - 1] === '/'
                                ? node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/${slug}`} />)
                                : node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/${slug}/`} />)
                    })
                }
            </Helmet>
        </>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Seo
